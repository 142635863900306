// import React, { useEffect, useContext } from 'react';
// import axios from 'axios';
// import { GlobalContext } from '../contexts/GlobalContext';

// const AxiosProvider = ({ children }) => {
//   const { token, setToken } = useContext(GlobalContext);
//   const refreshQueue = [];
//   let isRefreshing = false; // Variable pour vérifier si le rafraîchissement est en cours
//   let refreshPromise = null; // Promesse en cours de rafraîchissement

//   const refreshAccessToken = async () => {
//     const refreshToken = localStorage.getItem('refresh_token');
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/refresh`, { refresh_token: refreshToken });
//       const newToken = response.data.access_token.token;
//       const newRefreshToken = response.data.refresh_token.token;

//       setToken(newToken); // Mets à jour le token dans le contexte
//       localStorage.setItem('token', newToken);
//       localStorage.setItem('refresh_token', newRefreshToken);

//       // Réessayer toutes les requêtes en attente avec le nouveau token
//       refreshQueue.forEach(({ resolve, config }) => {
//         console.log('renvoi rapide de requete')
//         config.headers['Authorization'] = `Bearer ${newToken}`;
//         axios(config).then(resolve, console.log("renvoi succes")).catch(error => {
//           console.error('Retry request failed:', error);
//         });
//       });

//       refreshQueue.length = 0;
//       return newToken;
//     } catch (error) {
//       console.error('Unable to refresh token', error);
//       localStorage.removeItem('token');
//       setToken("");
//       refreshQueue.length = 0;
//       return null;
//     }
//   };

//   useEffect(() => {
//     console.log('TOKEN', token)
//     const interceptorId = axios.interceptors.response.use(
//       (response) => response,
//       async (error) => {
//         console.log("erreur de requete : ", error.config.headers.Authorization.split(' ')[1])
//         const originalRequest = error.config;
//         if (error.response && error.response.status === 401 && !originalRequest._retry) {
//           originalRequest._retry = true;

//           if (isRefreshing) {
//             // Si un rafraîchissement est déjà en cours, ajoute la requête en attente
//             return new Promise((resolve, reject) => {
//               refreshQueue.push({ resolve, reject, config: originalRequest });
//             });
//           }

//           if (!refreshPromise) {
//               // Si aucun rafraîchissement n'est en cours, démarre un nouveau rafraîchissement
//               isRefreshing = true;
//               refreshPromise = refreshAccessToken();
              
//               try {
//                   const newToken = await refreshPromise;
//                   console.log("nouveau renvoi de requete")
//                   console.log("token initial de la requete", error.config.headers.Authorization.split(' ')[1])
//                   console.log('state token actuel', token)
//                   console.log('newToken', newToken);
                  
//               if (newToken) {
//                 console.log("ultime comparaison state // newToken", token, newToken)
//                 axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
//                 return axios(originalRequest);
//               }
//             } finally {
//               isRefreshing = false;
//               refreshPromise = null;
//             }
//           }
//         }

//         // Si aucun rafraîchissement ou une erreur se produit, rejette la requête
//         return Promise.reject(error);
//       }
//     );

//     // Nettoyage de l’interceptor lors du démontage du composant
//     return () => {
//       axios.interceptors.response.eject(interceptorId);
//     };
//   }, [token, setToken]);

//   return <>{children}</>;
// };

// export default AxiosProvider;




/**
 * 
 * Up : try to refresh and not reload the page (invisible refresh for the user but actually not works 100%)
 * 
 * 
 * Down : Refresh token at the first 401 request response and after reload the page (works well but visible for users + error toasts to remove from components while response status === 401)
 */



import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../contexts/GlobalContext';

const AxiosProvider = ({ children }) => {
  const { token, setToken } = useContext(GlobalContext);
  let isRefreshing = false;
  let refreshPromise = null;
  const refreshQueue = [];

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/refresh`, { refresh_token: refreshToken });
      const newToken = response.data.access_token.token;
      const newRefreshToken = response.data.refresh_token.token;

      setToken(newToken);
      localStorage.setItem('token', newToken);
      localStorage.setItem('refresh_token', newRefreshToken);

      return newToken;

    } catch (error) {
      localStorage.removeItem('token');
      setToken("");
      return null;
    }
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (isRefreshing) {
            return new Promise((resolve, reject) => {
                refreshQueue.push({ resolve, reject, config: originalRequest });
            });
          }

          if (!refreshPromise) {
              isRefreshing = true;
              
              try {
                  const newToken = await refreshAccessToken();
                  
              if (newToken) {
                window.location.reload()
                return 
              }
            } finally {
              isRefreshing = false;
              refreshPromise = null;
            }
          }
        }

        // Si aucun rafraîchissement ou une erreur se produit, rejette la requête
        return Promise.reject(error);
      }
    );

    // Nettoyage de l’interceptor lors du démontage du composant
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [token, setToken]);

  return <>{children}</>;
};

export default AxiosProvider;
