import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { clickOutModal, closeContent } from '../../utils/functions'
import { useForm } from 'react-hook-form'
import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'

function UpdateTransactionFormMobileModal({account, transactionToUpdate}) {
    console.log(transactionToUpdate)
    const { token, mobileLimit } = useContext(GlobalContext)
    const { transactionTypes, categories } = useContext(DashboardContext)
    const {register, handleSubmit, formState:{errors}} = useForm()
    return (
        <div className='v2modal' id='newMobileTransactionModal' onClick={(e) => clickOutModal(e, 'newMobileTransactionModal')}>
                <div className='v2modal__content'> 
                    <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('newMobileTransactionModal')}  className='v2modal__closeButton'/>
                    <h2 className=''>Modifier une transaction</h2>

                    {/* <form 
                        className='currentAccount__newLine' 
                        // onSubmit={handleSubmit(updateTransaction)} 
                        id="addTransForm"
                    >
                        <FontAwesomeIcon icon={faXmark} className='currentAccount__newLine__closeButton' onClick={() => handleToggleTransactionForm()}/>

                        <h2 className='currentAccount__newLine__title'>Nouvelle transaction</h2>

                        <input type="date" defaultValue={new Date().toISOString().split('T')[0]}{...register('transaction_date')} required />
                        <select {...register('transaction_type_id')}>
                            {transactionTypes.map((type, index) => 
                                <option value={type.id} key={index}>{type.transaction_type_name}</option>
                            )}
                        </select>
                        <input type="text" {...register('transaction_counterparty')} placeholder='Tiers' required/>
                        <input type="text" {...register('transaction_description')} placeholder='Description'/>

                        <select onChange={(e) => e.target.value!=="" && handleSelectCategory(e.target.value)}>
                                <option value="">-- Catégorie --</option>
                                {categories.map((cat, index) => 
                                    <option value={cat.id} key={index}>{cat.category_name} {(currentSubcategory.length !== 0 && currentSubcategory.category_id === cat.id) && '-> '+currentSubcategory.subcategory_name}</option>
                                )}
                        </select>
                        
                        <input type="number" step='0.01' {...register('transaction_amount')} placeholder='Montant'/>

                        <button type="submit">Créer</button>
                    </form> */}

                    
                </div>
            </div>
    )
}

export default UpdateTransactionFormMobileModal