import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'


function ResetPassword() {
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const signedUrl = queryParams.get('signed_url')
  const tokenUrl = queryParams.get('token')

  const [status, setStatus] = useState('')

  const {register, handleSubmit, formState: {errors}, watch, reset} = useForm()
  const newPassword = watch('newPassword')

  useEffect(() => {
    if(!signedUrl){
      navigate('/')
    }
  }, [signedUrl])

  const resetPassword = async(data) => {
    try{
      const response = await API_FUNCTIONS.resetPassword(signedUrl, tokenUrl, data)
      
      if(response.status === 200){
        setStatus('passwordChanged')
      }else if(response.status === 409){
        TOASTS.error('Mot de passe déjà utilisé')
        reset()
        setStatus('oldPassword')
      }
    }catch(e){
      setStatus('error')
    }
  }

  return (
    <div className='mainContainer resetPassword'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Réinitialiser le mot de passe - Cloudy Pocket</title>
      </Helmet>

      
      {
        status === '' &&

        <div className='resetPassword__content'>
          <h1>Choisir un nouveau mot de passe</h1>
          <form onSubmit={handleSubmit(resetPassword)}>
            <label>Nouveau mot de passe</label>
            <input 
              type="password" 
              name="newPassword" 
              autoComplete='new-password'
              {...register('newPassword', {
                required: "Vous devez choisir un nouveau mot de passe",
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                  message: "Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                }
              })}
            />
            {errors.newPassword && <span className='danger registerForm__error'>{errors.newPassword.message}</span>}

            <label>Confirmez le mot de passe</label>
            <input 
              type="password" 
              name="confirmNewPassword" 
              {...register('confirmNewPassword', {
                required: "Vous devez confirmer votre nouveau mot de passe",
                validate: value => value === newPassword || "Les mots de passe ne correspondent pas"
              })}  
            />
            {errors.confirmNewPassword && <span className='danger registerForm__error'>{errors.confirmNewPassword.message}</span>}

            <button type="submit">Envoyer</button>
          </form>
        </div>
      }

      {
        status === "passwordChanged" && 
        <div className='resetPassword__content'>
          <h1>Mot de passe enregistré !</h1>
          <p>Votre nouveau mot de passe a bien été enregistré. Vous pouvez maintenant vous reconnecter.</p>
          <Link to="/connexion" className='btn btn--dark'>Me connecter</Link>
        </div>
      }

      {
        status === 'oldPassword' && 
        <div className='resetPassword__content'>
          <h1>Choisir un nouveau mot de passe</h1>

          <p className='danger'>Ce mot de passe a déjà été utilisé, veuillez choisir un nouveau mot de passe.</p>

          <form onSubmit={handleSubmit(resetPassword)}>
            <label>Nouveau mot de passe</label>
            <input 
              type="password" 
              name="newPassword" 
              autoComplete='new-password'
              {...register('newPassword', {
                required: "Vous devez choisir un nouveau mot de passe",
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                  message: "Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                }
              })}
            />
            {errors.newPassword && <span className='danger registerForm__error'>{errors.newPassword.message}</span>}

            <label>Confirmez le mot de passe</label>
            <input 
              type="password" 
              name="confirmNewPassword" 
              {...register('confirmNewPassword', {
                required: "Vous devez confirmer votre nouveau mot de passe",
                validate: value => value === newPassword || "Les mots de passe ne correspondent pas"
              })}  
            />
            {errors.confirmNewPassword && <span className='danger registerForm__error'>{errors.confirmNewPassword.message}</span>}

            <button type="submit">Envoyer</button>
          </form>
        </div>
      }

      {
        status === 'error' && 
        <div className='resetPassword__content'>
          <h1>Choisir un nouveau mot de passe</h1>

          <p className='danger'>Oups, une erreur est survenue...</p>
          <p>Si le problème persiste, veuillez contacter notre support.</p>
        </div>
      }

    </div>
  )
}

export default ResetPassword