import React,  {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './assets/sass/main.sass'
import {GlobalContext} from './contexts/GlobalContext'
import AxiosProvider from "./utils/AxiosProvider";
import { API_FUNCTIONS } from "./utils/apiFunctions";
import { setDefaultMode, setColorTheme, isTokenExpired } from "./utils/functions";
import useAuthToken from "./hooks/useAuthToken";

// PAGES
import Home from './pages/Home'
import Error from './pages/Error'
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import Conditions from './pages/Conditions'
import VerifyEmail from "./pages/VerifyEmail";

// COMPONENTS
import Header from './components/Header'
import Footer from './components/Footer'

import { ToastContainer } from "react-toastify";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";



function App() {  
  // useAuthToken()
  
  // const navigate = useNavigate()
  setDefaultMode()

  const [token, setToken] = useState(localStorage.getItem('token') || "")
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token') || "")
  const [user, setUser] = useState([])
  const [url, setUrl] = useState("")
  const [theme, setTheme] = useState(localStorage.getItem('colorTheme') || null)
  const [mode, setMode] = useState(document.body.className)
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)
  const [ mobileLimit, setMobileLimit ] = useState(Number(getComputedStyle(document.documentElement).getPropertyValue('--mobile-size')))
  const [ tabletLimit, setTabletLimit ] = useState(Number(getComputedStyle(document.documentElement).getPropertyValue('--tablet-size')))

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };


  const checkCurrentUser = async()=>{
    // if(isTokenExpired(token)){
    //   TOASTS.error('Vous êtes déconnecté')
    //   setToken("")
    //   localStorage.removeItem('token')
    //   // navigate('/connexion')
    // }else{
      try{
        const response = await API_FUNCTIONS.currentUser(token)
        
        if(response.data){
          setUser(response.data)
        }else{
          // setToken("")
          // localStorage.removeItem('token')
          // TOASTS.error('Vous êtes déconnecté')
        }
      }catch(e){
        // setToken("")
        // localStorage.removeItem('token')
        // TOASTS.error('Vous êtes déconnecté')
      }
    // }
    
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if(token){
      checkCurrentUser()
    }
  }, [token])

  useEffect(() => {
    if(theme){
      setColorTheme(theme)
    }
  }, [theme])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])



  
  

  
  

  return (
    <GlobalContext.Provider value={{token, setToken, refreshToken, setRefreshToken, user, setUser, setUrl, theme, setTheme, windowWidth, mobileLimit, tabletLimit, mode, setMode}}>
      <AxiosProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme= {localStorage.getItem('mode') === 'dark' ? 'light' : 'dark'}
        // transition: Bounce,
      />
        <Router>
          {url !== '/dashboard' && <Header/>}

            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/connexion" element={<Login/>} />
              <Route path="/nouveau_compte" element={<Register/>} />
              <Route path='/dashboard' element={<Dashboard/>}/>
              <Route path='/contact' element={<Contact/>}/>
              <Route path='/legal' element={<Conditions/>} />
              <Route path='/verify_email' element={<VerifyEmail/>} />
              <Route path="/forgot_password" element={<ForgottenPassword/>}/>
              <Route path="/reset_password" element={<ResetPassword/>} />
              <Route path="*" element={<Error/>} />
            </Routes>

          {url !== '/dashboard' && <Footer/> }
        </Router>
      </AxiosProvider>
    </GlobalContext.Provider>
  )
}

export default App;
