import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { API_FUNCTIONS } from '../utils/apiFunctions'

function ForgottenPassword() {
  const {register, handleSubmit, formState: {errors}} = useForm()

  const [isRequestSent, setIsRequestSent] = useState(false)

  const askResetLink = async(data) => {
    try{
      const response = await API_FUNCTIONS.sendEmailResetPassword(data)

    }catch(e){
    }finally{
      setIsRequestSent(true)
    }
  }


  return (
    <div className='mainContainer forgottenPassword'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mot de passe oublié ?</title>
      </Helmet>
      {
        !isRequestSent ?
          <div className='forgottenPassword__content'>
            <h1>Mot de passe oublié ?</h1>
            
            <p>Pas d'inquiétude, entrez l'adresse email de votre compte et nous vous enverrons un lien de réinitialisation.</p>

            <form onSubmit={handleSubmit(askResetLink)}>

              <label htmlFor="email">Entrez votre email</label>
              <input type="mail" name="email" id="email" {...register('email', {required: "Ce champ est obligatoire"})}/>

              <button type='submit'>Envoyer</button>
            </form>
          </div>
        :
          <div className='forgottenPassword__content'>
            <h1>Demande envoyée</h1>
            <p>Un lien de réinitialisation vous a été envoyé par mail.</p>
            <p>Si vous ne recevez pas cet email, pensez à vérifier dans vos spams.</p>
          </div>
      }


    </div>
  )
}

export default ForgottenPassword