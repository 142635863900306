import React, {useContext, useEffect, useState, useRef} from 'react'
import { DashboardContext } from '../../contexts/DashboardContext'
import {useForm} from 'react-hook-form'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { CSVLink } from "react-csv"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightFromBracket, faArrowsRotate, faBarsProgress,/* faBox, faCaretRight,*/ faEllipsisVertical, faFileExport, faGear, faMoneyBillTransfer, faPen, faPlus, faPlusCircle, faReceipt, faSquareCheck, faSquarePlus, faTrash, faUserPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faSquare } from '@fortawesome/free-regular-svg-icons'

import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { closeContent, getCurrentDate, getElementHeight, getElementWidth, openContent } from '../../utils/functions'

import NewSubcategoryModal from '../modals/NewSubcategoryModal'
import UpdateSubcategoryModal from '../modals/UpdateSubcategoryModal'
import SelectSubcategoryModal from '../modals/SelectSubcategoryModal'
import ShareAccountModal from '../modals/ShareAccountModal'
import AccountParametersModal from '../modals/AccountParametersModal'
import NewTransferModal from '../modals/NewTransferModal'
import PaymentPlanModal from '../modals/PaymentPlanModal'
import TicketPreview from '../TicketPreview'
import NewTransactionForm from '../NewTransactionForm'

import PDFExport from '../PDFExport'
import UpdateTransactionFormMobileModal from '../modals/UpdateTransactionFormMobileModal'


function DBOneAccount() {
    const tableRef = useRef(null);
    const settingsRef = useRef(null);
    
    const {token, windowWidth, mobileLimit} = useContext(GlobalContext)
    const {currentAccount, setDbUrl, transactionTypes, categories, accounts}=useContext(DashboardContext)
    
    const [isLoaded, setIsLoaded] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [selectedLine, setSelectedLine] = useState('')
    const [transactionToUpdate, setTransactionToUpdate] = useState(null)
    const [subcategories, setSubcategories] = useState([])
    const [tickets, setTickets] = useState([])
    const [isSettingsOpened, setIsSettingsOpened] = useState(false)
    
    const [updatingTransaction, setUpdatingTransaction] = useState([])
    
    


    /**
     * 
     * API FUNCTIONS
     * 
     */

    const loadingDatas = async() => {
        try{
            const [transactionsData, subcategoriesData, ticketsData] = await Promise.all([
                API_FUNCTIONS.allTransactions({account_id: currentAccount.id}, token),
                API_FUNCTIONS.allSubCategories({account_id: currentAccount.id}, token),
                API_FUNCTIONS.allTickets(token)
            ])

            setTransactions(transactionsData.data.data)
            setSubcategories(subcategoriesData.data.data)
            setTickets(ticketsData.data.data)

        }catch(e){

        }finally{
            setIsLoaded(true)
        }
    }
    

    const updateTransaction = async()=>{
        const request={
            ...updatingTransaction,
            account_id: currentAccount.id,
            category_id: (currentSubcategory.length === 0 && currentCategoryId) ? parseInt(currentCategoryId) : (currentSubcategory.length !== 0 ? currentSubcategory.category_id: null ),
            subcategory_id: currentSubcategory ? currentSubcategory.id : null
        }
        try{

            const response = await API_FUNCTIONS.updateTransaction(updatingTransaction.id, request, token)
            console.log("update response ", response)
            if(response.data){
                const updatedTransactions = transactions.map(tr => (
                    tr.id === updatingTransaction.id ? 
                        response.data.transaction
                    :
                        tr
                ))
                setTransactions(updatedTransactions)
                currentAccount.account_balance_checked = response.data.balance_checked
                currentAccount.account_balance_upcoming = response.data.balance_upcoming
            }
            setCurrentCategoryId('')
            setCurrentSubcategory([])
            setUpdatingTransaction([])
        }catch(e){
            console.error(e);
        }
    }

    const deleteTransaction = async(id)=>{
        try{
            const response = await API_FUNCTIONS.deleteTransaction(id, token)
            if(response.data.code === 200){
                setTransactions(prev => prev.filter(item => item.id !== id))
                currentAccount.account_balance_checked = response.data.balance_checked
                currentAccount.account_balance_upcoming = response.data.balance_upcoming
                document.removeEventListener('mousedown', handleClickOutside)
                setSelectedLine('')
            }
        }catch(e){

        }
    }

    const check = async(transaction, isTransfer)=>{
        try{
            const response = await API_FUNCTIONS.check(transaction.id, {is_transfer_account: isTransfer}, token)
            console.log('check response', response)
            if(response.data.code === 200){
                
                isTransfer ? (transaction.transaction_is_transfer_checked = 1) : (transaction.transaction_is_checked = 1)

                const updatedTransactions = transactions.map(t => (
                    t.id === transaction.id ?
                        transaction
                    :
                        t
                ))
                setTransactions(updatedTransactions)
                currentAccount.account_balance_checked = response.data.balance_checked
                currentAccount.account_balance_upcoming = response.data.balance_upcoming

            }
        }catch(e){
            console.error(e);
        }
    }

    const unCheck = async(transaction, isTransfer) => {
        try{
            const response = await API_FUNCTIONS.unCheck(transaction.id, {is_transfer_account: isTransfer}, token)
            if(response.data){
                isTransfer ? (transaction.transaction_is_transfer_checked = 0) : (transaction.transaction_is_checked = 0)

                const updatedTransactions = transactions.map(t => (
                    t.id === transaction.id ?
                        transaction
                    :
                        t
                ))
                setTransactions(updatedTransactions)
                currentAccount.account_balance_checked = response.data.balance_checked
                currentAccount.account_balance_upcoming = response.data.balance_upcoming
            }
        }catch(e){
            console.error(e);
        }
    }


    

    useEffect(() => {
        if(currentAccount.length !== 0){
            loadingDatas()
        }else{
            setDbUrl('dbAccounts')
        }
    }, [])



    /**
     * 
     * SETTINGS MENU
     * 
     */

    const displaySettings = (e)=>{
        
        if(!isSettingsOpened){
            let menu = document.getElementById('settingsMenu')
            menu.style.display = 'flex'

            const width = getElementWidth(menu)
            const height = getElementHeight(menu)

            if(mobileLimit >= window.innerWidth){ // Mobile mode
                menu.style.width = window.innerWidth+'px'
                menu.style.height = window.innerHeight+'px'
                menu.style.top = 0
                menu.style.left = 0
                menu.style.opacity = '1'
            }else{ // Desktop mode
    
                let x = e.clientX
                let y = e.clientY
                if(x + width + 15 > window.innerWidth)x= x - width - 5
                if(y + height + 15 > window.innerHeight)y = y - height - 15
    
                menu.style.left = x+'px'
                menu.style.top = y+'px'
                menu.style.height = height+'px'
                menu.style.opacity = '1'
    
            }
            document.addEventListener('mousedown', handleClickOutsideSettings)
            setIsSettingsOpened(true)
        }
    }

    const handleClickOutsideSettings = (e)=>{
        if(settingsRef.current && !settingsRef.current.contains(e.target) && mobileLimit < window.innerWidth){
                // document.getElementById('settingsMenu').style.height = '0'
                // document.getElementById('settingsMenu').style.opacity = '0'
                // document.getElementById('settingsMenu').style.display = 'none'
                // document.removeEventListener('mousedown', handleClickOutsideSettings)
                // setIsSettingsOpened(false)
                handleCloseSettingsMenu()
        }
    }
    const handleCloseSettingsMenu = () => {
        document.getElementById('settingsMenu').style.height = '0'
        document.getElementById('settingsMenu').style.opacity = '0'
        document.getElementById('settingsMenu').style.display = 'none'
        document.removeEventListener('mousedown', handleClickOutsideSettings)
        setIsSettingsOpened(false)
    }



    /**
     * 
     * TRANSACTION TABLE INTERACTIONS
     * 
     */

    useEffect(() => {
        if(transactionToUpdate){
            openModal('updateTransactionFormMobileModal')
        }
    }, [transactionToUpdate])


    const changeLine = (id)=>{
        if(parseInt(selectedLine) >= 0){
            document.getElementById('line'+selectedLine).classList.remove('currentAccount__table__line--active')
            document.getElementById('line'+selectedLine).classList.add('currentAccount__table__line')
        }
        document.getElementById('line'+id).classList.add('currentAccount__table__line--active')
        document.getElementById('line'+id).classList.remove('currentAccount__table__line')
        setSelectedLine(id)
    }
    const handleClickOutside = (e)=>{
        if(tableRef.current && !tableRef.current.contains(e.target)){
            if(parseInt(selectedLine) >= 0){
                document.getElementById('line'+selectedLine).classList.remove('currentAccount__table__line--active')
                document.getElementById('line'+selectedLine).classList.add('currentAccount__table__line')
                setSelectedLine('')
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [selectedLine])



    const displayTransactionMenu = (e, id) => {
        const menu = document.getElementById(id)
        if(mobileLimit >= window.innerWidth){ // Mobile mode
            const width = 100
            const height = 100
            let x = e.clientX - (width/2)
            let y = e.clientY - (height/2)
            if(x+width+15 > window.innerWidth)x = x - width - 5
            if(y+height+15 > window.innerHeight)y = window.innerHeight - height - 15
    
            menu.style.width = width+'px'
            menu.style.height = height+'px'
            menu.style.left = x+'px'
            menu.style.top = y+'px'
        }else{ // Desktop and tablet mode

            const width = getElementWidth(menu)
            const height = getElementHeight(menu)
            let x = e.clientX
            let y = e.clientY
            if(x+width+15 > window.innerWidth)x = x - width - 5
            if(y+height+15 > window.innerHeight)y = window.innerHeight - height - 15
    
            menu.style.width = width+'px'
            menu.style.height = height+'px'
            menu.style.left = x+'px'
            menu.style.top = y+'px'
        }
        document.addEventListener('mousedown', e => handleClickOutsideTransactionMenu(e, id))
    }
    
    const hideTransactionMenu = (id) => {

        document.getElementById(id).style.width = '0'
        document.getElementById(id).style.height = '0'
        document.removeEventListener('mousedown', handleClickOutsideTransactionMenu(id))
    }
    const handleClickOutsideTransactionMenu = (e, id)=>{
        const transactionMenu = document.getElementById(id);
        if (transactionMenu && !transactionMenu.contains(e.target)) {

            transactionMenu.style.width = '0';
            document.removeEventListener('mousedown', handleClickOutsideTransactionMenu);
        }
    }



    const [isTransactionFormOpened, setIsTransactionFormOpened ] = useState(false)
    const handleToggleTransactionForm = () => {
        const form = document.getElementById('addTransactionForm')

        if(isTransactionFormOpened){
            form.style.display = 'none'
            
            setIsTransactionFormOpened(false)
        }else{
            form.style.left = '0'
            form.style.top = '0'
            form.style.display = "flex"

            setIsTransactionFormOpened(true)
        }

    }


    /**
     * 
     * HANDLE FUNCTIONS TO MANIPULATE DATAS
     */


    //transactions
    const handleAddTransaction= (newTransaction)=>{
        setTransactions(prev => [...prev, newTransaction])
        setCurrentCategoryId('')
        setCurrentSubcategory([])
    }
    const handleUpdateTransactions = (newTransaction) => {
        setTransactions(prev => [...prev, newTransaction])
    }
    
    const updateEditingTransaction = (fieldname, value)=>{
        setUpdatingTransaction(prev => ({...prev, [fieldname]: value}))
    }
    
    //Categories and subcategories
    const handleAddSubcategory = (newSubcategory) => {
        setSubcategories(prev => [...prev, newSubcategory])
    }
    const handleUpdateSubcategory = (subcategory) =>{
        const updatedSubcategories = subcategories.map(sub => (
            sub.id === subcategory.id ? 
            subcategory
            :
            sub
        ))
        setSubcategories(updatedSubcategories)
    }
    const handleRemoveSubcategory = (id)=>{
        setSubcategories(prev => prev.filter(item => item.id !== id))
    }
    
    // Tickets
    const handleDeleteTicket = (idToDelete) => {
        setTickets(prev => prev.filter(item => item.id !== idToDelete))
    }

    const [currentCategoryId, setCurrentCategoryId] = useState('')
    const [currentSubcategory, setCurrentSubcategory] = useState([])
    const handleSelectCategory = (id)=>{
        setCurrentCategoryId(id)
        setCurrentSubcategory([])
        openContent('selectSubcategoryModal')
    }
    const handleSelectSubcategory = (subcategory)=>{
        setCurrentSubcategory(subcategory)
    }



    /**
     * 
     * EXPORT FUNCTIONS
     * 
     */


    const file=[]
    const exportToCsv = () => {
        
        file.push(["date", "mode", "Tiers", "Description", "Catégorie", "Sous-catégorie", "Montant", "Pointée"])
        
        transactions.map(t => file.push([
            t.transaction_date,
            transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_name,
            t.transaction_counterparty,
            t.transaction_description,
            t.category_id ? categories.find(item => item.id === t.category_id).category_name : null,
            // t.category_id && t.subcategory_id ? subcategories.find(item => item.id === t.subcategory_id).subcategory_name : null,
            t.transaction_amount,
            t.transaction_id_checked
        ]))
        
        return file
    }
    
    
    /**
     * 
     * CONTEXT MENU & Page comportement
     * 
     */
    
    function preventContextMenu(e) {
        e.preventDefault();
    }

    function customContextMenu(e){
        if(e.target.closest('tr')){
            displayTransactionMenu(e, 'transactionMenu-'+e.target.closest('tr').id)
        }else if(e.target.closest('.currentAccount__mobileContainer__item')){
            console.log(e.target.closest('.currentAccount__mobileContainer__item').id);
            displayTransactionMenu(e, 'transactionMobileMenu-'+e.target.closest('.currentAccount__mobileContainer__item').id)
        }else{
            displaySettings(e)
        }
    }


    function preventTextSelection(event) {
        event.preventDefault();
    }

    
    useEffect(() => {

        window.addEventListener('contextmenu', preventContextMenu)
        window.addEventListener('contextmenu', customContextMenu)
        window.addEventListener('selectstart', preventTextSelection)
        return () => {
            window.removeEventListener('contextmenu', preventContextMenu)
            window.removeEventListener('contextmenu', customContextMenu)
            window.removeEventListener('selectstart', preventTextSelection);
        };
    }, [])


    /**
     * MODALS
     */

    const [displayedModal, setDisplayedModal] = useState([]); // Take all the current modals to put it on the return
    const [modals, setModals] = useState([
        {name: 'newTransferModal',
        status: false,
        component: <NewTransferModal currentAccount={currentAccount} handleUpdateTransactions={handleUpdateTransactions} subcategories={subcategories}/>},
        {name: 'paymentPlanModal',
        status: false,
        component: <PaymentPlanModal/>},
        {name: 'subcategoryModal',
        status: false,
        component: <NewSubcategoryModal account_id ={currentAccount.id} addSubcategory={handleAddSubcategory}/>},
        {name: 'updateSubcategoryModal',
        status: false,
        component: <UpdateSubcategoryModal subcategories={subcategories} updateSubcategory={handleUpdateSubcategory} removeSubcategory={handleRemoveSubcategory} />},
        {name: 'shareAccountModal',
        status: false,
        component: <ShareAccountModal account_id={currentAccount.id}/>},
        {name: 'accountParametersModal',
        status: false,
        component: <AccountParametersModal account={currentAccount}/>},
        {name: 'updateTransactionFormMobileModal',
        status: false,
        component: <UpdateTransactionFormMobileModal account={currentAccount} transactionToUpdate={transactionToUpdate}/>},
    ])

    const openModal = (name) => {
        if(document.getElementById(name)){
            openContent(name)
        }else{
            const selectedModal = modals.find(item => item.name === name)

            if(selectedModal){
                selectedModal.status = true
                setModals(modals.map(modal => 
                    modal.name === name ? 
                        selectedModal
                    :
                        modal
                ))
                setDisplayedModal(prev => [...prev, selectedModal.component])
            }
        }
    }
    
    return (
    <article className='currentAccount'>
        {/* <FontAwesomeIcon icon={faArrowRightFromBracket} className='currentAccount__exitBtn danger' onClick={() => setDbUrl('dbAccounts')}/> */}
        <FontAwesomeIcon icon={faArrowLeftLong} className='currentAccount__exitBtn' onClick={() => setDbUrl('dbAccounts')}/>
        <FontAwesomeIcon icon={faGear} className='currentAccount__settings' onClick={(e) => !isSettingsOpened && displaySettings(e)} style={{display:isSettingsOpened && 'none' }}/>

        <div className='currentAccount__settings__menu' id='settingsMenu' ref={settingsRef}>
            <FontAwesomeIcon icon={faXmark} className='currentAccount__settings__menu__closeButton' onClick={() => {document.getElementById('settingsMenu').style.display = 'none'; setIsSettingsOpened(false)}}/>
            <div className="currentAccount__settings__menu__button" onClick={() => {openModal('newTransferModal');/*openContent('newTransferModal');*/ handleCloseSettingsMenu()}}><FontAwesomeIcon icon={faMoneyBillTransfer} /> Créer un transfert</div>
            <div className="currentAccount__settings__menu__button" onClick={() => {openModal('paymentPlanModal');/*openContent('paymentPlanModal');*/ handleCloseSettingsMenu()}}><FontAwesomeIcon icon={faCalendar} /> Échéanciers</div>
            <div className="currentAccount__settings__menu__button" onClick={() => {openModal('subcategoryModal');/*openContent('subcategoryModal');*/ handleCloseSettingsMenu()}}><FontAwesomeIcon icon={faSquarePlus} /> Créer une sous-catégorie</div>
            <div className="currentAccount__settings__menu__button" onClick={() => {openModal('updateSubcategoryModal');/*openContent('updateSubcategoryModal');*/ handleCloseSettingsMenu()}} ><FontAwesomeIcon icon={faBarsProgress} /> Modifier / supprimer une sous-catégorie</div>
            <div className='currentAccount__settings__menu__separator'></div>
            <PDFDownloadLink
                document={<PDFExport account={currentAccount} transactions={transactions} categories={categories} subcategories={subcategories} transactionTypes={transactionTypes}/>}
                fileName="document.pdf"
                style={{ textDecoration: 'none', color: 'black' }}
                docbaseurl="http://localhost:3000/"
                className="currentAccount__settings__menu__button"
                onClick={() => handleCloseSettingsMenu()}
            >
                {({ blob, url, loading, error }) =>
                    loading ? 'Chargement...' : 
                    (
                        <div className="currentAccount__settings__menu__button--export">
                            <FontAwesomeIcon icon={faFileExport} /> Export PDF
                        </div>
                    )
                }
            </PDFDownloadLink>
            {isLoaded &&
                // <div className="currentAccount__settings__menu__button" >
                //     <CSVLink className="currentAccount__settings__menu__button--export" data={exportToCsv()} filename={currentAccount.account_name.replace(' ','_').toLowerCase()+'-'+getCurrentDate()}>
                //         <FontAwesomeIcon icon={faFileExport} /> Export CSV
                //     </CSVLink>
                // </div>

                // <div className="currentAccount__settings__menu__button" >
                    <CSVLink className="currentAccount__settings__menu__button currentAccount__settings__menu__button--export" data={exportToCsv()} filename={currentAccount.account_name.replace(' ','_').toLowerCase()+'-'+getCurrentDate()} onClick={() => handleCloseSettingsMenu()}>
                        <FontAwesomeIcon icon={faFileExport} /> Export CSV
                    </CSVLink>
                // </div>
            }
            <div className='currentAccount__settings__menu__separator'></div>
            <div className="currentAccount__settings__menu__button" onClick={() => {openModal('shareAccountModal');/*openContent('shareAccountModal');*/ handleCloseSettingsMenu()}}><FontAwesomeIcon icon={faUserPlus}/> Partager le compte</div>
            <div className='currentAccount__settings__menu__separator'></div>
            <div className="currentAccount__settings__menu__button danger" onClick={() => {openModal('accountParametersModal');/*openContent('accountParametersModal');*/ handleCloseSettingsMenu()}}><FontAwesomeIcon icon={faGear}/> Paramètres avancés</div>
        </div>


        <FontAwesomeIcon icon={faReceipt} className='currentAccount__ticketBtn'onClick={() => openContent('ticketPreview')}/>


        <div className='currentAccount__balance'>
            <p className={currentAccount.account_balance_checked < 0 ? "danger" : undefined}>Solde pointé : {currentAccount.account_balance_checked} {isLoaded && currentAccount.currency.currency_symbol}</p>
            <p className={currentAccount.account_balance_upcoming < 0 ? "danger" : undefined}>à venir : {currentAccount.account_balance_upcoming} {isLoaded && currentAccount.currency.currency_symbol}</p>
            <p className={((currentAccount.account_balance_checked*100) + (currentAccount.account_balance_upcoming*100))/100 < 0 ? "danger" : undefined}>Solde prévisionnel : {((currentAccount.account_balance_checked*100) + (currentAccount.account_balance_upcoming*100))/100} {isLoaded && currentAccount.currency.currency_symbol}</p>
        </div>




        <table className='currentAccount__table' id='accountTable' ref={tableRef}>
            <thead className='currentAccount__table__head'>

                <tr>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>Description</th>
                    <th>Catégorie</th>
                    <th>Montant</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {isLoaded && 
                    transactions.sort((a, b) => {
                        const dateA = new Date(a.transaction_date)
                        const dateB = new Date(b.transaction_date)
                        return dateB-dateA
                    }).map((t, index) => 
                    <tr key={index} id={"line"+index} onClick={() => changeLine(index)} className='currentAccount__table__line'>

                        <td className='currentAccount__table__date'>
                            {
                                updatingTransaction.id === t.id ?
                                    <input type="date" value={updatingTransaction.transaction_date} onChange={e => updateEditingTransaction('transaction_date', e.target.value)}/>
                                :
                                    t.transaction_date
                            }
                        </td>
                        {/* <td>
                            {
                                updatingTransaction.id === t.id ?
                                    <select value={updatingTransaction.transaction_type_id} onChange={e => updateEditingTransaction('transaction_type_id',e.target.value)}>    
                                        {
                                            transactionTypes.map((tr, index) => 
                                                <option value={tr.id} key={index}>{tr.transaction_type_name}</option>
                                            )
                                        }
                                    </select>
                                :
                                    transactionTypes.find(item => item.id === parseInt(t.transaction_type_id)).transaction_type_name
                            }
                        </td> */}
                        {/* <td>
                            {
                                updatingTransaction.id === t.id ? 
                                    t.transfer_with ?
                                        <span>Transfert vers{accounts.find(item => item.id === t.transfer_with).account_name}</span>
                                    :
                                        <input type="text" value={updatingTransaction.transaction_counterparty} onChange={e => updateEditingTransaction('transaction_counterparty', e.target.value)}/>
                                    
                                :
                                    t.transfer_with ? 
                                        t.transfer_with === currentAccount.id ?
                                            <span>Transfert depuis [{accounts.find(item => item.id === t.account_id) ? accounts.find(item => item.id === t.account_id).account_name : "Compte supprimé"}]</span>
                                        :
                                            <span>Transfert vers [{accounts.find(item => item.id === t.transfer_with) ? accounts.find(item => item.id === t.transfer_with).account_name : "Compte supprimé"}]</span>
                                    :
                                        t.transaction_counterparty
                            }
                        </td> */}
                        <td>
                            {
                                updatingTransaction.id === t.id ? 
                                    <div className='currentAccount__table__line__multipleFields'>
                                        <div>

                                        {t.transfer_with ?
                                            <span>Transfert vers{accounts.find(item => item.id === t.transfer_with).account_name}</span>
                                        :
                                            <input type="text" value={updatingTransaction.transaction_counterparty} onChange={e => updateEditingTransaction('transaction_counterparty', e.target.value)}/>}
                                        </div>

                                        <select value={updatingTransaction.transaction_type_id} onChange={e => updateEditingTransaction('transaction_type_id',e.target.value)}>    
                                        {
                                            transactionTypes.map((tr, index) => 
                                                <option value={tr.id} key={index}>{tr.transaction_type_name}</option>
                                            )
                                        }
                                    </select>
                                    </div>
                                    
                                :
                                <div className='currentAccount__table__line__multipleFields'>
                                    <div>

                                        {t.transfer_with ? 
                                            t.transfer_with === currentAccount.id ?
                                                <span>Transfert depuis [{accounts.find(item => item.id === t.account_id) ? accounts.find(item => item.id === t.account_id).account_name : "Compte supprimé"}]</span>
                                            :
                                                <span>Transfert vers [{accounts.find(item => item.id === t.transfer_with) ? accounts.find(item => item.id === t.transfer_with).account_name : "Compte supprimé"}]</span>
                                        :
                                            t.transaction_counterparty
                                        }
                                    </div>
                                    <div className='currentAccount__table__type'>
                                        {transactionTypes.find(item => item.id === parseInt(t.transaction_type_id)).transaction_type_name}
                                    </div>
                                </div>
                            }
                        </td>
                        <td>
                            {
                                updatingTransaction.id === t.id ? 
                                    <input type="text" value={updatingTransaction.transaction_description} onChange={e => updateEditingTransaction('transaction_description', e.target.value)}/>
                                :
                                    t.transaction_description
                            }
                        </td>
                        <td>
                            {
                                updatingTransaction.id === t.id ? 
                                    <select value={currentCategoryId} onChange={(e) => e.target.value!=="" && handleSelectCategory(e.target.value)}>
                                        <option value="">-- Catégorie --</option>
                                        {categories.map((cat, index) => 
                                            <option value={cat.id} key={index} onClick={() => parseInt(currentCategoryId) === cat.id && handleSelectCategory(cat.id)}>{cat.category_name} {(currentSubcategory.length !== 0 && currentSubcategory.category_id === cat.id) && '-> '+currentSubcategory.subcategory_name}</option>
                                        )}
                                    </select>
                                :
                                    t.category_id && 
                                    <div>
                                        <div className='currentAccount__table__category'>{categories?.find(item => item.id === parseInt(t.category_id)).category_name}</div>
                                        {(subcategories.length!==0 && t.subcategory_id) &&
                                            <div className='currentAccount__table__subcategory'>
                                                {subcategories?.find(item => item.id === t.subcategory_id).subcategory_name}
                                            </div>
                                        }
                                    </div>
                            }
                        </td>
                        <td>
                            {
                                updatingTransaction.id === t.id ? 
                                <input type="number" value={updatingTransaction.transaction_amount} onChange={e => updateEditingTransaction('transaction_amount', e.target.value)}/>
                                :
                                <div className='currentAccount__table__amount'>
                                        {
                                            ((transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_is_debit && t.transfer_with !== currentAccount.id) || (!transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_is_debit && t.transfer_with === currentAccount.id)) ?
                                                <div className='currentAccount__table__amount--debit danger'>{t.transaction_amount}{currentAccount.currency.currency_symbol}</div>
                                            :
                                                <div className='currentAccount__table__amount--credit'>{t.transaction_amount}{currentAccount.currency.currency_symbol}</div>
                                        }
                                </div>
                                
                            }
                        </td>
                        <td>
                            {
                                updatingTransaction.id === t.id ?
                                    <FontAwesomeIcon icon={faArrowsRotate} onClick={() => updateTransaction()}/>
                                :
                                    <FontAwesomeIcon 
                                        className='currentAccount__table__checkbox'
                                        icon={t.account_id === currentAccount.id ? 
                                                t.transaction_is_checked ? faSquareCheck : faSquare 
                                            : 
                                                t.transaction_is_transfer_checked ? faSquareCheck : faSquare
                                        } 
                                        
                                        onClick={() => t.account_id === currentAccount.id ?
                                                t.transaction_is_checked ? unCheck(t, 0) : check(t, 0)
                                            :
                                                t.transaction_is_transfer_checked ? unCheck(t, 1) : check(t, 1)
                                        }
                                    />
                            }
                        </td>
                        <td className='currentAccount__table__settings'>
                            <div className='currentAccount__table__settings__menu' id={'transactionMenu-line'+index}>
                                <div onClick={() => {
                                    setUpdatingTransaction(t);
                                    hideTransactionMenu('transactionMenu-line'+index);
                                    setCurrentCategoryId(t.category_id);
                                    setCurrentSubcategory(subcategories.find(item => item.id === t.subcategory_id) || []);
                                }}>
                                    <FontAwesomeIcon icon={faPen} className='currentAccount__table__settings__menu__button'/>
                                </div>
                                <div onClick={() => deleteTransaction(t.id)}><FontAwesomeIcon icon={faTrash} className='currentAccount__table__settings__menu__button danger'/></div>  
                            </div>
                            {
                                updatingTransaction.id === t.id ?
                                    <div className='currentAccount__table__settings__icon' onClick={() => {setUpdatingTransaction([]); setCurrentCategoryId(''); setCurrentSubcategory([])}}>
                                        <FontAwesomeIcon icon={faXmark} className='danger'/> 
                                    </div>
                                :
                                    
                                    <div className='currentAccount__table__settings__icon' onClick={e => displayTransactionMenu(e, 'transactionMenu-line'+index)}>
                                        <FontAwesomeIcon icon={faEllipsisVertical}/>  
                                    </div>

                            }
                            

                        </td>
                    </tr>
                    )
                }
            </tbody>
        </table>
            

        <div className='currentAccount__mobileContainer'>
            {transactions.map((t, index) => 
            <div key={index} id={"line"+index} className='currentAccount__mobileContainer__item'>
                <div className='currentAccount__mobileContainer__item__header'>
                    <h2 className='currentAccount__mobileContainer__item__header__title'>{t.transfer_with ? 
                        t.transfer_with === currentAccount.id ?
                            'Transfert depuis ' + accounts.find(item => item.id === t.account_id) ? accounts.find(item => item.id === t.account_id).account_name : "Compte supprimé"
                        :
                            'Transfert vers ' + accounts.find(item => item.id === t.transfer_with) ? accounts.find(item => item.id === t.transfer_with).account_name : "Compte supprimé"
                    :
                        t.transaction_counterparty
                    }</h2>
                    
                    <FontAwesomeIcon icon={faEllipsisVertical} className='currentAccount__mobileContainer__item__header__settingButton' onClick={() => {setTransactionToUpdate(t)}}/> 
                </div>
                <div className='currentAccount__mobileContainer__item__body'>
                    <div className='currentAccount__mobileContainer__item__body__content'>
                        <div className='currentAccount__mobileContainer__item__body__content__type'>
                            {transactionTypes.find(item => item.id === parseInt(t.transaction_type_id)).transaction_type_name}
                        </div>
                        <div className='currentAccount__mobileContainer__item__body__content__middle'>
                            <div className='currentAccount__mobileContainer__item__body__content__middle__status'>
                                <FontAwesomeIcon 
                                    className='currentAccount__table__checkbox'
                                    icon={t.account_id === currentAccount.id ? 
                                            t.transaction_is_checked ? faSquareCheck : faSquare 
                                        : 
                                            t.transaction_is_transfer_checked ? faSquareCheck : faSquare
                                    } 
                                    
                                    onClick={() => t.account_id === currentAccount.id ?
                                            t.transaction_is_checked ? unCheck(t, 0) : check(t, 0)
                                        :
                                            t.transaction_is_transfer_checked ? unCheck(t, 1) : check(t, 1)
                                    }
                                />
                            </div>

                            <div className='currentAccount__mobileContainer__item__body__content__middle__infos'>
                                <div className='currentAccount__mobileContainer__item__body__content__middle__infos__date'>
                                    {t.transaction_date}
                                </div>
                                <div className='currentAccount__mobileContainer__item__body__content__middle__infos__description'>
                                    {t.transaction_description}
                                </div>
                            </div>

                        </div>
                        <div className='currentAccount__mobileContainer__item__body__content__category'>
                            {categories?.find(item => item.id === parseInt(t.category_id))?.category_name}
                            {(subcategories.length!==0 && t.subcategory_id) &&
                                ' / ' + subcategories?.find(item => item.id === t.subcategory_id)?.subcategory_name
                            }
                        </div>
                    </div>
                    <div className='currentAccount__mobileContainer__item__body__amount'>
                        {
                            ((transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_is_debit && t.transfer_with !== currentAccount.id) || (!transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_is_debit && t.transfer_with === currentAccount.id)) ?
                                <span className='danger'>{(t.transaction_amount * -1)}{currentAccount.currency.currency_symbol}</span>
                            :
                                t.transaction_amount + currentAccount.currency.currency_symbol
                        }
                    </div>
                    <div className='currentAccount__mobileContainer__item__menu' id={'transactionMobileMenu-line'+index}>
                        <div onClick={() => {
                            setUpdatingTransaction(t);
                            // hideTransactionMobileMenu('transactionMenu-line'+index);
                            setCurrentCategoryId(t.category_id);
                            setCurrentSubcategory(subcategories.find(item => item.id === t.subcategory_id) || []);
                        }}>
                            <FontAwesomeIcon icon={faPen} className='currentAccount__mobileContainer__item__menu__button'/>
                        </div>
                        <div className='currentAccount__mobileContainer__item__menu__center'>
                            <FontAwesomeIcon icon={faXmark} className=''/>
                        </div>
                        <div onClick={() => deleteTransaction(t.id)}><FontAwesomeIcon icon={faTrash} className='currentAccount__mobileContainer__item__menu__button danger'/></div>  
                    </div>
                </div>
            </div>
        )}
        </div>

        { mobileLimit >= window.innerWidth &&
            <div className='currentAccount__newMobileTransactionButton' onClick={() => handleToggleTransactionForm()}>
                <FontAwesomeIcon icon={faPlusCircle}/>
            </div>
        }
        



        { isLoaded && <NewTransactionForm currentCategoryId={currentCategoryId} currentSubcategory={currentSubcategory} currentAccount={currentAccount} handleAddTransaction={handleAddTransaction} handleSelectCategory={handleSelectCategory} handleToggleTransactionForm={handleToggleTransactionForm}/>}
        

        <SelectSubcategoryModal categoryId={currentCategoryId} subcategories={subcategories} handleSelectSubcategory={handleSelectSubcategory}/>
        { isLoaded && <TicketPreview tickets={tickets} handleDeleteTicket={handleDeleteTicket} />}

        {/* DISPLAY MODALS */}
        {displayedModal}

        {/* <NewSubcategoryModal account_id ={currentAccount.id} addSubcategory={handleAddSubcategory}/> */}
        {/* <UpdateSubcategoryModal subcategories={subcategories} updateSubcategory={handleUpdateSubcategory} removeSubcategory={handleRemoveSubcategory} />  */}
        {/* <ShareAccountModal account_id={currentAccount.id}/> */}
        {/* { isLoaded && <NewTransferModal currentAccount={currentAccount} handleUpdateTransactions={handleUpdateTransactions} subcategories={subcategories}/> } */}
        {/* { isLoaded && <PaymentPlanModal/> } */}
        {/* { isLoaded && <AccountParametersModal account={currentAccount}/>} */}

    </article>
  )
}

export default DBOneAccount