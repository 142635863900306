import { API_FUNCTIONS } from "./apiFunctions"

export const setDefaultMode = () => {
  if(localStorage.getItem('mode')){
    document.body.className = (localStorage.getItem('mode'))
  }else{
    if(window.matchMedia('(prefers-color-scheme: dark)').matches===true){
      document.body.className = "dark"
      localStorage.setItem('mode', "dark")
    }else{
      document.body.className = "light"
      localStorage.setItem('mode', "light")
    }
  }
}

export const changeMode = (setMode) => {

    if (document.body.className === 'light' || (!document.body.className &&  window.matchMedia('(prefers-color-scheme: dark)')).matches===false ) {
      document.body.className = 'dark'
      localStorage.setItem('mode', 'dark')
      setMode('dark')
    } else {
      document.body.className = 'light'
      localStorage.setItem('mode', 'light')
      setMode('light')
    }
  
}


export const setColorTheme = async(id) => {

  try{
    const response = await API_FUNCTIONS.getTheme(id);  
    if(response.data.data){
      const theme = response.data.data
      document.documentElement.style.setProperty('--light-background',theme["light-background"] )
      document.documentElement.style.setProperty('--light-background-subtle',theme["light-background-subtle"] )
      document.documentElement.style.setProperty('--light-element',theme["light-element"] )
      document.documentElement.style.setProperty('--light-element-hovered',theme["light-element-hovered"] )
      document.documentElement.style.setProperty('--light-element-selected',theme["light-element-selected"] )
      document.documentElement.style.setProperty('--light-border',theme["light-border"] )
      document.documentElement.style.setProperty('--light-border-hovered',theme["light-border-hovered"] )
      document.documentElement.style.setProperty('--light-border-selected',theme["light-border-selected"] )
      document.documentElement.style.setProperty('--light-solid',theme["light-solid"] )
      document.documentElement.style.setProperty('--light-solid-hovered',theme["light-solid-hovered"] )
      document.documentElement.style.setProperty('--light-text-low',theme["light-text-low"] )
      document.documentElement.style.setProperty('--light-text-high',theme["light-text-high"] )

      document.documentElement.style.setProperty('--dark-background',theme["dark-background"] )
      document.documentElement.style.setProperty('--dark-background-subtle',theme["dark-background-subtle"] )
      document.documentElement.style.setProperty('--dark-element',theme["dark-element"] )
      document.documentElement.style.setProperty('--dark-element-hovered',theme["dark-element-hovered"] )
      document.documentElement.style.setProperty('--dark-element-selected',theme["dark-element-selected"] )
      document.documentElement.style.setProperty('--dark-border',theme["dark-border"] )
      document.documentElement.style.setProperty('--dark-border-hovered',theme["dark-border-hovered"] )
      document.documentElement.style.setProperty('--dark-border-selected',theme["dark-border-selected"] )
      document.documentElement.style.setProperty('--dark-solid',theme["dark-solid"] )
      document.documentElement.style.setProperty('--dark-solid-hovered',theme["dark-solid-hovered"] )
      document.documentElement.style.setProperty('--dark-text-low',theme["dark-text-low"] )
      document.documentElement.style.setProperty('--dark-text-high',theme["dark-text-high"] )

      localStorage.setItem('colorTheme', theme.id)
      return theme
    }
  }catch(e){
    return e
  }
}

export const isTokenExpired = (token) => {
  if(!token)return 0
  const tokenData = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = tokenData.exp * 1000
  return Date.now() >= expirationTime
}


export const openContent = (id) => {
  document.getElementById(id).style.display = "flex"
}

export const closeContent = (id) => {
  document.getElementById(id).style.display = "none"
}

export const clickOutModal = (e, id) => {
  if(e.target.attributes.id){
      if(e.target.attributes.id.textContent === id){
          closeContent(id)
      }
  }
}

export const listenEscapeKey = (id)=>{
  document.addEventListener('keydown', (e)=>{
    if(e.key === "Escape"){
          closeContent(id)
      }
  })
}


export const autoScroll = () => {
  if (window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const getCurrentDate=()=>{
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const day = now.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`;
}


//return the theorycal height off an element
export const getElementHeight = (element) => {
  var clone = element.cloneNode(true);
  clone.style.visibility = 'hidden'; 
  clone.style.height = 'auto';
  document.body.appendChild(clone); 
  var height = clone.offsetHeight; 
  document.body.removeChild(clone); 
  return height; 
}

export const getElementWidth = (element) => {
  var clone = element.cloneNode(true);
  clone.style.visibility = 'hidden'; 
  clone.style.width = 'auto';
  document.body.appendChild(clone); 
  var width = clone.offsetWidth; 
  document.body.removeChild(clone); 
  return width; 
}