import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { GlobalContext } from '../contexts/GlobalContext'
import { DashboardContext } from '../contexts/DashboardContext'

// import DashboardHeader from '../components/dashboard/DashboardHeader'
// import DashboardMenu from '../components/dashboard/DashboardMenu'
import DBMenu from '../components/dashboard/DBMenu'
import DBHeader from '../components/dashboard/DBHeader'
import DBHome from '../components/dashboard/DBHome'
import DBAccounts from '../components/dashboard/DBAccounts'
import DBTickets from '../components/dashboard/DBTickets'
import DBSettings from '../components/dashboard/DBSettings'
import DBOneAccount from '../components/dashboard/DBOneAccount'

import Spinner from '../components/Spinner'


import { API_FUNCTIONS } from '../utils/apiFunctions'
import { autoScroll, isTokenExpired } from '../utils/functions'
import { TOASTS } from '../utils/toasts'
import DBAdmin from '../components/dashboard/DBAdmin'
import { Helmet } from 'react-helmet'

function Dashboard() {
    const {setUrl, token} = useContext(GlobalContext)
    const navigate = useNavigate()
    setUrl(useLocation().pathname)
    const [isLoaded, setIsLoaded] = useState(false)
    const [currentAccount, setCurrentAccount] = useState([])
    const [dbUrl, setDbUrl] = useState(localStorage.getItem('dbUrl') || 'dbAccounts')
    const [transactionTypes, setTransactionTypes] = useState([])
    const [categories, setCategories] = useState([])
    const [accounts, setAccounts] = useState([])
    const [sharedWith, setSharedWith] = useState([])

    /**
     * CHECK CURRENT USER
     */

    // const getCurrentUser = async() => {
    //   try{
    //     const response = await API_FUNCTIONS.currentUser(token)
    //     if(!response.data)navigate('/connexion')
    //   }catch(e){
    //     console.error(e);
    //   }
    // }

    // useEffect(() => {
    //   autoScroll()
    //   if(token && !isTokenExpired(token)){
    //     getCurrentUser()
    //   }else{
    //     navigate('/connexion')
    //   }
    // }, [dbUrl, token])


    /**
     * LOADING DATAS
     */

    const loadingDatas = async()=> {
      try{
        const [typesDatas, categoriesDatas] = await Promise.all([
          API_FUNCTIONS.allTransactionTypes(token),
          API_FUNCTIONS.allCategories(token)
        ])

        if(typesDatas.data?.code === 200){
          setTransactionTypes(typesDatas.data.data)
        }else{
          TOASTS.error('Oups, une erreur est survenue lors du chargement des données...')
        }
        
        if(categoriesDatas.data?.code === 200){
          setCategories(categoriesDatas.data.data)
        }else{
          TOASTS.error('Oups, une erreur est survenue lors du chargement des données...')
        }
      }catch(e){
        TOASTS.error('Oups, une erreur est survenue lors du chargement des données...')
        console.error(e)
      }finally{
        setIsLoaded(true)
      }
    }

    // useEffect(() => {
    //   if(token){
    //     loadingDatas()
    //   }
      
    // }, [token])

    useEffect(() => {
      if(!isLoaded){
        loadingDatas()
      }
      
    }, [isLoaded])

    useEffect(() => {
      const mobileLimit = Number(getComputedStyle(document.documentElement).getPropertyValue('--mobile-size'))
      if(isLoaded && mobileLimit >= window.innerWidth){
        (document.getElementById('dashboard').style.height = window.innerHeight - 100+'px')
      }
    }, [isLoaded])

    

    return (
    <DashboardContext.Provider  value={{
      setDbUrl, dbUrl, 
      currentAccount, setCurrentAccount, 
      transactionTypes, setTransactionTypes, 
      categories, 
      accounts, setAccounts, 
      sharedWith, setSharedWith,
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tableau de bord - Cloudy Pocket</title>
      </Helmet>
        <DBHeader/>
        <div className="globalDashboardContainer">

            {isLoaded &&<DBMenu/>}
            {isLoaded &&
              <div className='dashboard' id="dashboard">
                  { dbUrl === 'dbHome' && <DBHome/>}
                  { dbUrl === 'dbAccounts' && <DBAccounts/>}
                  { dbUrl === 'dbOneAccount' && <DBOneAccount/>}
                  { dbUrl === 'dbTickets' && <DBTickets/>}
                  { dbUrl === 'dbSettings' && <DBSettings/> }
                  { dbUrl === 'dbAdmin' && <DBAdmin/> }
              </div>
            }
        </div>
    </DashboardContext.Provider>
    
  )
}

export default Dashboard