import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { closeContent, clickOutModal, listenEscapeKey } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'
import { useNavigate } from 'react-router-dom'

function UpdateUserModal() {
    const navigate = useNavigate()
    const { token, setToken, user, setUser } = useContext(GlobalContext)

    const {register, handleSubmit, formState: {errors}, reset} = useForm()

    const [ updatingUser, setUpdatingUser ] = useState(false)

    const userLogout = async() => {
        try{
          await API_FUNCTIONS.logout(token)
        }catch(e){
          console.error("Erreur lors de la déconnexion", e)
        }finally{
          localStorage.removeItem('token')
            setToken('')
            navigate('/')
            TOASTS.success('Vous êtes déconnecté. Merci pour votre visite et à bientôt !')
        }
      }


    const updateUser = async(data) => {
        try{
            const request = {
                ...user,
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                id: user.id
            }
            const response = await API_FUNCTIONS.updateUser(request, token)
            if(response.data?.code === 200){
                if(data.email !== user.email){
                    userLogout()
                }
                setUser(response.data.data)
                reset()
                TOASTS.success('Les informations de votre compte ont bien été mises à jour')
                closeContent('updateUserModal')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la modification de votre compte...')
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue lors de la modification de votre compte...')
            console.error(e);
        }
    }


    listenEscapeKey('updateUserModal')
    return (
        <div className='modal' id='updateUserModal' onClick={(e) => clickOutModal(e, 'updateUserModal')}>
                <div className='modal__content'> 
                    <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('updateUserModal')}  className='modal__closeButton'/>
                    <h2 className='success'>Modifier les informations du compte</h2>

                    <form onSubmit={handleSubmit(updateUser)}>
                        <label>Prénom</label>
                        <input type="text" defaultValue={user.firstname} {...register('firstname', {required: 'Ce champ est obligatoire'})}/>
                        {errors.firstname && <span className='danger'>{errors.firstname.message}</span>}
                        
                        
                        <label>Nom</label>
                        <input type="text" defaultValue={user.lastname} {...register('lastname', {required: 'Ce champ est obligatoire'})}/>
                        {errors.lastname && <span className='danger'>{errors.lastname.message}</span>}

                        <label>email<br/>(Si vous modifiez votre adresse email, vous serez déconnecté et un nouvel email de vérification sera envoyé à votre nouvelle adresse.)</label>
                        <input type="email" defaultValue={user.email} {...register('email', {required: 'Ce champ est obligatoire'})}/>
                        {errors.email && <span className='danger'>{errors.email.message}</span>}

                        {/* <div>
                            <input type="checkbox" onChange={e => console.log('test', e)}/>
                            <label>Changer de mot de passe ?</label>
                        </div> */}

                        <button type='submit'>Envoyer</button>
                    </form>

                </div>
            </div>
    )
}

export default UpdateUserModal